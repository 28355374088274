import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";


const NewcalendarPage1 = React.lazy(() => import('./page/NewcalendarPage1'));
const NewcalendarPage2 = React.lazy(() => import('./page/NewcalendarPage2'));
const NewcalendarPage3 = React.lazy(() => import('./page/NewcalendarPage3'));
const NewcalendarPage4 = React.lazy(() => import('./page/NewcalendarPage4'));
const NewcalendarPageCom = React.lazy(() => import('./page/NewcalendarPageCom'));
const Complate = React.lazy(() => import('./page/complate'));
const information = React.lazy(() => import('./page/information'));
const NotFoundPage = React.lazy(() => import('./page/NotFoundPage'));
const Getting = React.lazy(() => import('./page/Getting'));
function App() {
  return (
    <div className="">
        <Switch>
            <Route exact path="/" render={() => (<Redirect to="/getting" />)} />
            <Route  path="/getting" component={Getting} />
            <Route  path="/1" component={NewcalendarPage1} />
            <Route  path="/2" component={NewcalendarPage2} />
            <Route  path="/3" component={NewcalendarPage3} />
            <Route  path="/4" component={NewcalendarPage4} />
            <Route  path="/5" component={NewcalendarPageCom} />
            <Route  path="/complete" component={Complate} />
            <Route  path="/info" component={information} />
            <Route component={NotFoundPage} />
        </Switch>
    </div>
  );
}

export default App;
